import React, { createContext, useReducer } from 'react';
import { ConfirmState, IConfirmAction } from './confirm-dialog.types';
import { initialState, confirmDialogReducer } from './confirm-dialog-reducer';

interface IConfirmContext {
  state: ConfirmState;
  dispatch: React.Dispatch<IConfirmAction>;
}

const ConfirmContext = createContext<IConfirmContext>({
  state: initialState(),
  dispatch: () => {
    return;
  },
});

const ConfirmContextProvider: React.FC<React.PropsWithChildren> = ({ children }): JSX.Element => {
  const [state, dispatch] = useReducer(confirmDialogReducer, initialState());

  return <ConfirmContext.Provider value={{ state, dispatch }}>{children}</ConfirmContext.Provider>;
};

export { ConfirmContext, ConfirmContextProvider };
