import { useMutation, useQueryClient } from '@tanstack/react-query';
import { requestClient } from 'src/lib/services/api/request-api';
import { authQueries } from 'src/api/queries';
import { useToast } from 'src/components/ui/use-toast';
import { useTranslation } from 'react-i18next';

export const useDefaultToMultiOutcomeRequestTransformMutation = () => {
  const client = useQueryClient();
  const { toast } = useToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (args: Parameters<typeof requestClient.transformToMultiOutcome>) =>
      requestClient.transformToMultiOutcome(...args),
    onSuccess: (data, variables, context) => {
      const requestId = variables[0];

      toast({ description: t('Request transformed to multi outcome.') });

      client.invalidateQueries({
        queryKey: [authQueries.request, requestId],
      });
      client.invalidateQueries({
        queryKey: [authQueries.requests],
      });
    },
  });
};
