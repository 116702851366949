import React from 'react';
import 'src/assets/scss/theme.scss';
import { IntlProvider } from 'react-intl';
import ModalLoaderProvider from 'src/components/ModalLoader/Provider';
import AppRoutes from 'src/routes/app-routes';
import AppUpdateDialog from 'src/features/sw-update/app-update-dialog';
import { Toaster } from 'src/components/ui/toaster';
import { ConfirmContextProvider, ConfirmDialog } from 'src/features/confirm-dialog';

const App: React.FC = () => {
  return (
    <ConfirmContextProvider>
      <ModalLoaderProvider>
        <IntlProvider locale={'en'}>
          <Toaster />
          <AppUpdateDialog />
          <AppRoutes />
          <ConfirmDialog />
        </IntlProvider>
      </ModalLoaderProvider>
    </ConfirmContextProvider>
  );
};

export default App;
