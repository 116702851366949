import React from 'react';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'src/components/ui/dialog';
import { Button } from 'src/components/ui/button';
import { X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { CardErrorFallback } from 'src/features/fallback';
import { RequestQueryProvider } from 'src/features/requests/use-request-query-context';
import { DecoratedRequestProvider } from 'src/features/requests/use-decorated-request-context';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { useUpdateRequestDueDate } from 'src/features/requests/update-request-due-date-dialog/use-update-request-due-date';
import {
  UpdateRequestDueDateDialogForm,
  UpdateRequestDueDateDialogFormSkeleton,
} from 'src/features/requests/update-request-due-date-dialog/update-request-due-date-dialog-form';

const UpdateRequestDueDateDialog: React.FC = () => {
  const { t } = useTranslation();
  const { requestId, setRequestDueDateDialogOpen } = useUpdateRequestDueDate();

  return (
    <Dialog
      modal
      open={!!requestId}
      onOpenChange={(open) => {
        if (!open) {
          setRequestDueDateDialogOpen(undefined);
        }
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle className={'tw-text-2xl tw-font-bold tw-tracking-tight'}>
            {t('Update request estimated due date')}
          </DialogTitle>
          <DialogClose asChild>
            <Button type={'button'} variant={'ghost'} size={'icon'}>
              <X size={20} />
            </Button>
          </DialogClose>
        </DialogHeader>
        <QueryErrorResetBoundary>
          {({ reset }) => (
            <ErrorBoundary
              fallbackRender={({ error, resetErrorBoundary }: any) => (
                <CardErrorFallback
                  error={error}
                  resetErrorBoundary={resetErrorBoundary}
                  className={'tw-border-0 tw-shadow-none'}
                />
              )}
              onReset={reset}
            >
              <React.Suspense fallback={<UpdateRequestDueDateDialogFormSkeleton />}>
                {!!requestId && (
                  <RequestQueryProvider id={requestId}>
                    {({ data }) => (
                      <DecoratedRequestProvider request={data}>
                        <UpdateRequestDueDateDialogForm
                          onSubmit={() => {
                            setRequestDueDateDialogOpen(undefined);
                          }}
                        />
                      </DecoratedRequestProvider>
                    )}
                  </RequestQueryProvider>
                )}
              </React.Suspense>
            </ErrorBoundary>
          )}
        </QueryErrorResetBoundary>
      </DialogContent>
    </Dialog>
  );
};

export { UpdateRequestDueDateDialog };
