import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useRequestQueryContext } from 'src/features/requests/use-request-query-context';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { useCompanyQueryContextData } from 'src/features/companies/use-company-query-context';
import { useProfile } from 'src/models';
import { useMediaDownload } from 'src/features/media';
import { useRequestStatusMap } from 'src/pages/RequestPage/RequestStatusChangeForm/RequestStatusChangeForm.utils';
import { useOpenRequestStatusChangeModal } from 'src/features/requests/request-status-change/use-open-request-status-change-modal';
import { Button } from 'src/components/ui/button';
import { If } from 'src/components/If';
import { Link } from 'src/components/ui/link';
import { appRoutes } from 'src/routes';
import { RequestAttentionAction } from 'src/pages/RequestPage/RequestActionComponents/RequestAttentionAction';
import { authQueries } from 'src/api/queries';
import { ChevronLeft, Download, FileVideo2, FolderInput, Megaphone, Video } from 'lucide-react';
import { PageHeading, PageHeadingTitle } from 'src/components/ui/page-heading';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from 'src/components/ui/breadcrumb';
import { defaultSortFilter } from 'src/api/services/RequestClient';
import { requestsResponseHelper } from 'src/features/requests/requests-response.helper';
import { DefaultToMultiOutcomeRequestTransformButton } from 'src/features/requests/request-table/default-to-multi-outcome-request-transform-button';
import { Loader2 } from 'src/components/ui/animated-loader2';

const RequestDefaultPageContentTop = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const client = useQueryClient();

  const requestQuery = useRequestQueryContext();
  const request = useDecoratedRequestContext();
  const company = useCompanyQueryContextData();

  const user = useProfile({ enabled: false, retry: false });

  const { downloadAll, isDownloading } = useMediaDownload();

  const { canChangeStatus } = useRequestStatusMap();

  const { openRequestStatusChangeModal } = useOpenRequestStatusChangeModal();

  const changeStatusButtonProps: React.ComponentProps<typeof Button> = {
    onClick: () =>
      openRequestStatusChangeModal({ requestId: request.id, companyId: company?.id ?? '' }),
    className: 'tw-order-first md:tw-order-last tw-grow sm:tw-grow-0',
    size: 'sm',
  };

  if (user.isAdmin && !request.hasEditor) {
    changeStatusButtonProps['title'] = t(
      "Status is not editable on requests that doesn't have assigned editor.",
    )!;
    changeStatusButtonProps['disabled'] = true;
  }

  if (!canChangeStatus(request?.status)) {
    changeStatusButtonProps['title'] = t("Status can't be changed for completed requests.")!;
    changeStatusButtonProps['disabled'] = true;
  }

  return (
    <div ref={ref} className={className} {...props}>
      <If
        when={!!children}
        else={
          <div className={'tw-mb-4 tw-flex tw-flex-wrap tw-gap-4 md:tw-mb-10'}>
            <div
              className={
                'tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-4 md:tw-justify-start'
              }
            >
              <PageHeading>
                <Breadcrumb>
                  <BreadcrumbList className={'tw-flex-nowrap'}>
                    <BreadcrumbItem>
                      <Button
                        variant={'unset'}
                        size={'iconXs'}
                        onClick={() => {
                          if (location.state && location.state.from) {
                            navigate(location.state.from);
                          } else {
                            navigate({
                              pathname: generatePath(appRoutes.companyRequestsList, {
                                companyId: company?.id ?? '',
                              }),
                              search: defaultSortFilter,
                            });
                          }
                        }}
                      >
                        <ChevronLeft className={'tw-size-4'} />
                      </Button>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <BreadcrumbLink asChild>
                        <Link to={appRoutes.companiesList}>{t('Companies')}</Link>
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                      <BreadcrumbLink asChild>
                        <Link
                          to={generatePath(appRoutes.company, { companyId: company?.id ?? '' })}
                        >
                          {company?.name ?? t('unset')!}
                        </Link>
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                      <BreadcrumbLink asChild>
                        <Link
                          to={{
                            pathname: generatePath(appRoutes.companyRequestsList, {
                              companyId: company?.id ?? '',
                            }),
                            search: defaultSortFilter,
                          }}
                          state={location.state}
                        >
                          {t('Requests')}
                        </Link>
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbPage>
                      {t('common:revisions', {
                        defaultValue: 'Revisions',
                      })}
                    </BreadcrumbPage>
                  </BreadcrumbList>
                </Breadcrumb>
                <PageHeadingTitle className={'tw-flex-nowrap'}>
                  <div>
                    <Video className={'tw-size-6'} />
                  </div>
                  <span className={'tw-line-clamp-1'}>
                    {request?.title ?? t('unset', { ns: 'common' })!}
                  </span>
                </PageHeadingTitle>
              </PageHeading>
            </div>
            <div
              className={'tw-ms-auto tw-flex tw-flex-wrap tw-items-center tw-justify-end tw-gap-2'}
            >
              <If when={request.canRequestAttention}>
                <RequestAttentionAction
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  form={{
                    requestId: request.id,
                    initialValues: {
                      attention_text: request?.attention_text || '',
                    },
                    onSubmit: async ({ attention_text }) => {
                      requestQuery.setQueryData({
                        attention_text,
                        requested_attention_at: new Date().toDateString(),
                      });
                      client.invalidateQueries({
                        queryKey: [authQueries.requests, request?.status],
                      });
                    },
                  }}
                  as={Button}
                  variant={'outline'}
                  size={'iconStartSm'}
                  className={'tw-grow sm:tw-grow-0'}
                >
                  <Megaphone className={'tw-size-5'} />
                  {t('Request attention')}
                </RequestAttentionAction>
              </If>

              <If when={requestsResponseHelper.canBeTransformedToMultiOutcome(request)}>
                <DefaultToMultiOutcomeRequestTransformButton
                  size={'iconStartSm'}
                  variant={'outline'}
                  className={'tw-grow sm:tw-grow-0'}
                  request={request}
                >
                  {(props) => (
                    <>
                      {props.isLoading ? (
                        <Loader2 size={16} variant={'unset'} />
                      ) : (
                        <FolderInput size={16} />
                      )}
                      {t('Transform')}
                    </>
                  )}
                </DefaultToMultiOutcomeRequestTransformButton>
              </If>

              <If when={request.hasMedia}>
                <Button
                  variant={'outline'}
                  size={'iconStartSm'}
                  className={'tw-grow sm:tw-grow-0'}
                  disabled={isDownloading}
                  onClick={() => {
                    downloadAll(request.media ?? []);
                  }}
                >
                  <Download className={'tw-size-5'} />
                  {t('Download files')}
                </Button>
              </If>

              <If when={request.hasRevisions}>
                <Button
                  asChild
                  variant={'outline'}
                  size={'iconStartSm'}
                  className={'tw-grow sm:tw-grow-0'}
                >
                  <Link
                    variant={'unset'}
                    to={generatePath(appRoutes.requestRevisions, {
                      companyId: company?.id ?? '',
                      requestId: request.id,
                    })}
                  >
                    <FileVideo2 className={'tw-size-5'} />
                    {t('Revisions')}
                  </Link>
                </Button>
              </If>

              <Button {...changeStatusButtonProps}>{t('Change status')}</Button>
            </div>
          </div>
        }
      >
        {children}
      </If>
    </div>
  );
});
RequestDefaultPageContentTop.displayName = 'RequestDefaultPageContentTop';

export { RequestDefaultPageContentTop };
