import { Api } from 'src/api';
import { httpConfig } from 'src/lib/modules/http';
import { AxiosRequestConfig } from 'axios';
import { PaginatedResponse } from 'src/types/generics';

const baseUrl = `${httpConfig.options.baseURL}/capacities`;

export type CompanyEditorDailyEditingCapacityResponse = {
  paid_editing_time: number;
  available_editing_time: number;
  company_id: string;
  editor_id: string;
};

export const filter = (
  config?: AxiosRequestConfig,
): Promise<PaginatedResponse<CompanyEditorDailyEditingCapacityResponse>> =>
  Api.http.get(`${baseUrl}`, config);
