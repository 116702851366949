import React, { MutableRefObject, useState } from 'react';
import { RevisionChatComment } from './revision-chat-comment';
import { useRevisionChatCommentReplyQueryContext } from 'src/features/comments/use-request-reply-comments';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { If } from 'src/components/If';
import { Loader2 } from 'lucide-react';
import { CommentResponse } from 'src/lib/services/api/comment-api';

const RevisionChatCommentReplySheetComments = React.forwardRef<
  HTMLDivElement,
  { replyComment: CommentResponse }
>(({ replyComment }, ref) => {
  const [currentCommentEditing, setCurrentCommentEditing] = useState<string>('');
  const {
    comments,
    isLoadingMore,
    canLoadMore,
    fetchNextPage,
    invalidate,
    reactReplyCommentMutation,
    removeReplyCommentMutation,
  } = useRevisionChatCommentReplyQueryContext();

  return (
    <div className={'tw-relative tw-flex tw-h-full tw-grow'}>
      <ScrollArea
        className={'!tw-absolute tw-inset-0 !tw-m-0 tw-px-6 [&>div]:!tw-flex [&>div]:tw-grow'}
        viewportProps={{
          className: '[&>div]:!tw-block',
        }}
        scrollRef={ref as MutableRefObject<HTMLDivElement | null>}
        onScrollUp={(ref) => {
          if (!ref.scrollTop && canLoadMore && !isLoadingMore) {
            fetchNextPage();
          }
        }}
      >
        <div className={'tw-flex tw-flex-col-reverse tw-gap-4 tw-pt-6'}>
          <If when={isLoadingMore}>
            <div className={'tw-flex tw-w-full tw-pb-4'}>
              <Loader2 className={'tw-mx-auto tw-size-8 tw-animate-spin tw-text-secondary'} />
            </div>
          </If>
          {comments.map((comment: CommentResponse) => (
            <RevisionChatComment
              key={comment.id}
              comment={comment}
              replyDisabled
              deleteMutation={removeReplyCommentMutation}
              reactMutation={(commentId: string, reaction: string) =>
                reactReplyCommentMutation.mutate([commentId, { reaction }])
              }
              currentCommentEditing={currentCommentEditing}
              setCurrentCommentEditing={setCurrentCommentEditing}
              onSuccessEdit={() => { invalidate(); }}
            />
          ))}

          {/* First comment, load always last */}
          {!canLoadMore && (
            <RevisionChatComment
              replyDisabled
              comment={replyComment}
              currentCommentEditing={currentCommentEditing}
              setCurrentCommentEditing={setCurrentCommentEditing}
            />
          )}
        </div>
      </ScrollArea>
    </div>
  );
});

RevisionChatCommentReplySheetComments.displayName = 'RevisionChatCommentReplySheetComments';

export default RevisionChatCommentReplySheetComments;
