const unique = <T>(arr: T[]): T[] =>
  arr.filter((value, index, self) => self.indexOf(value) === index);

const wrap = <T = unknown>(args: T | T[]): T[] => {
  return Array.isArray(args) ? args : [args];
};

export const arr = {
  unique,
  wrap,
} as const;
