import React from 'react';
import { useController } from 'react-hook-form';
import { DatePicker } from 'src/components/ui/date-picker';

export const DatePickerInput: React.FC<
  React.ComponentPropsWithoutRef<typeof DatePicker> & {
    name: string;
  }
> = ({ name, ...props }) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
  });

  return (
    <DatePicker
      {...props}
      selected={value}
      /** type hack */
      {...{ onSelect: onChange }}
    />
  );
};
