import { useContext } from 'react';
import { ConfirmContext } from './confirm-dialog-provider';
import { ConfirmFn, ConfirmUtils } from './confirm-dialog.types';
import { HIDE_CONFIRM, initialState, SHOW_CONFIRM } from './confirm-dialog-reducer';

let resolveConfirm: (value: unknown) => void;

const useConfirm = (): ConfirmUtils => {
  const { state, dispatch } = useContext(ConfirmContext);

  const onConfirm = () => {
    closeConfirm();
    resolveConfirm && resolveConfirm(true);
  };

  const onCancel = () => {
    closeConfirm();
    resolveConfirm && resolveConfirm(false);
  };

  const onClose = () => {
    closeConfirm();
    resolveConfirm && resolveConfirm(null);
  };

  const confirm: ConfirmFn = (text, options) => {
    dispatch({
      type: SHOW_CONFIRM,
      payload: {
        text: text,
        show: true,
        options: options,
      },
    });

    return new Promise((resolve) => {
      resolveConfirm = resolve;
    });
  };

  const closeConfirm = () => {
    dispatch({
      type: HIDE_CONFIRM,
      payload: initialState(),
    });
  };

  return { confirm, onConfirm, onCancel, onClose, state };
};

export { useConfirm };
