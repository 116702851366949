import { requestFlow, requestStatus } from 'src/lib/services/api/request-api';

export const requestsResponseHelper: {
  isFolder: (request: { flow: string | any }) => boolean;
  isQueued: (request: { status: string | any }) => boolean;
  isEditing: (request: { status: string | any }) => boolean;
  isDelivered: (request: { status: string | any }) => boolean;
  isCompleted: (request: { status: string | any }) => boolean;
  hasVideoRevision: (request: { revisions_count?: number }) => boolean;
  hasAnyRevision: (request: { revisions_count?: number }) => boolean;
  isApproved: (request: { approved_at?: string | null }) => boolean;
  needsReedit: (request: { requested_reedit_at?: string | null }) => boolean;
  canBeTransformedToMultiOutcome: (request: {
    flow?: string | any;
    revisions_count?: number;
  }) => boolean;
} = {
  isFolder: ({ flow }) => [requestFlow.repurpose, requestFlow.multiOutcome].includes(flow),
  isQueued: ({ status }) => requestStatus.queued === status,
  isEditing: ({ status }) => requestStatus.currentlyEditing === status,
  isDelivered: ({ status }) => requestStatus.delivered === status,
  isCompleted: ({ status }) => requestStatus.complete === status,
  hasVideoRevision: ({ revisions_count }) => (revisions_count ?? 0) > 1,
  hasAnyRevision: ({ revisions_count }) => (revisions_count ?? 0) > 0,
  isApproved: ({ approved_at }) => !!approved_at,
  needsReedit: ({ requested_reedit_at }) => !!requested_reedit_at,
  canBeTransformedToMultiOutcome: ({ flow, revisions_count }) =>
    [requestFlow.default].includes(flow) && revisions_count === 0,
};
