import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/ui/button';
import { useConfirm } from 'src/features/confirm-dialog';
import { CallableChildren, render } from 'src/lib/utils';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { useDefaultToMultiOutcomeRequestTransformMutation } from 'src/features/requests/use-default-to-multi-outcome-request-transform-mutation';

export const DefaultToMultiOutcomeRequestTransformButton: React.FC<
  Omit<React.ComponentPropsWithoutRef<typeof Button>, 'children'> & {
    request: {
      id: string;
    };
    onConfirm?: () => void;
    children?: CallableChildren<
      ReturnType<typeof useDefaultToMultiOutcomeRequestTransformMutation>
    >;
  }
> = ({ children, request, onConfirm, ...props }, ref) => {
  const transformToMultiOutcomeMutation = useDefaultToMultiOutcomeRequestTransformMutation();
  const { confirm } = useConfirm();
  const { t } = useTranslation();

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            type={'button'}
            variant={'ghost'}
            disabled={transformToMultiOutcomeMutation.isLoading}
            size={'icon'}
            onClick={async () => {
              const confirmed = await confirm(
                t(
                  'This action will transform the request to multi-outcome which cannot be undone.',
                )!,
              );
              if (confirmed) {
                transformToMultiOutcomeMutation.mutate([request.id]);
                onConfirm?.();
              }
            }}
            {...props}
          >
            {render(children, transformToMultiOutcomeMutation)}
          </Button>
        </TooltipTrigger>
        <TooltipContent>{t('Transform to multi-outcome')}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
