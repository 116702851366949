import React from 'react';
import { RequestsTableResponse } from 'src/api/services/RequestClient';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { cn, str } from 'src/lib/utils';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import { RequestStatusDetails } from 'src/features/requests/request-table/request-status-details';
import { useRequestPreview } from 'src/features/requests/request-preview-sheet/use-request-preview';
import { cva, VariantProps } from 'class-variance-authority';
import { requestFlow } from 'src/lib/services/api/request-api';
import { RequestPriorityBadge } from 'src/features/requests/request-priority-badge';
import { Trans, useTranslation } from 'react-i18next';
import { Sparkles, Truck } from 'lucide-react';
import { Badge } from 'src/components/ui/badge';
import { formatDueDate, formatEditingTime } from 'src/features/requests/request.helper';
import { RequestExpectedEditingTimeBadge } from 'src/features/requests/request-expected-editing-time-badge';
import { RequestExpectedReeditingTimeBadge } from 'src/features/requests/request-expected-reediting-time-badge';
import { requestsConditionalUiHelper } from 'src/features/requests/requests-conditional-ui.helper';

const kanbanCardVariants = cva(
  'tw-group tw-relative tw-cursor-pointer tw-rounded-lg tw-border tw-transition-colors data-[active=true]:tw-border-brand data-[active=true]:hover:tw-border-brand',
  {
    variants: {
      variant: {
        default:
          'tw-bg-neutral-50 hover:tw-border-brand-subtle hover:tw-bg-brand-100 data-[active=true]:tw-bg-brand-200 data-[active=true]:hover:tw-bg-brand-300',
        highlight:
          'tw-border-brand-subtle tw-bg-brand-200 hover:tw-bg-brand-300 data-[active=true]:hover:tw-bg-brand-300',
        unset: '',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

type Props = {
  request: RequestsTableResponse;
  cardProps?: (
    request: RequestsTableResponse,
  ) => React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof kanbanCardVariants> & { active?: boolean };
  cardTop?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const RequestKanbanCard: React.FC<Props> = ({
  request,
  cardProps,
  cardTop,
  className: bodyClassName,
  ...bodyProps
}) => {
  const { previewRequest } = useRequestPreview();
  const { t } = useTranslation();

  const { className, variant, active, ...restCardProps } = cardProps?.(request) || {};

  return (
    <div
      key={`card-${request.id}`}
      data-id={request.id}
      data-state={variant || 'default'}
      data-active={active || false}
      className={cn(kanbanCardVariants({ variant, className }))}
      {...restCardProps}
      onClick={async () => {
        previewRequest(request.id);
      }}
    >
      {cardTop}
      <div className={cn('tw-p-4', bodyClassName)} {...bodyProps}>
        <TooltipProvider>
          <div className={'tw-flex tw-flex-col tw-gap-3'}>
            <div className={'tw-flex tw-flex-col'}>
              <div className={'tw-line-clamp-2 tw-break-words tw-font-medium tw-text-text-strong'}>
                {request.flow === 'repurpose' && (
                  <Tooltip>
                    <TooltipTrigger>
                      <Sparkles size={12} className={'tw-mr-1'} />
                    </TooltipTrigger>
                    <TooltipContent>{t('Repurposed with AI')}</TooltipContent>
                  </Tooltip>
                )}
                <Tooltip>
                  <TooltipTrigger>{request.title}</TooltipTrigger>
                  <TooltipContent className={'tw-max-w-96 tw-break-words'}>
                    {request.title}
                  </TooltipContent>
                </Tooltip>
              </div>

              {(request.flow === requestFlow.multiOutcome ||
                request.flow === requestFlow.repurpose) && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className={'tw-py-1.5'}>
                      <div
                        className={
                          'tw-relative tw-flex tw-h-1.5 tw-w-full tw-overflow-hidden tw-rounded-full tw-bg-neutral-200 tw-opacity-50'
                        }
                      >
                        <span
                          className={'tw-h-full tw-bg-neutral-500 tw-transition-all'}
                          style={{
                            width: `${
                              request.queued_outcomes_count > 0
                                ? (request.queued_outcomes_count * 100) / request.outcomes_count
                                : 0
                            }%`,
                          }}
                        ></span>
                        <span
                          className={'tw-h-full tw-bg-yellow-500 tw-transition-all'}
                          style={{
                            width: `${
                              request.editing_outcomes_count > 0
                                ? (request.editing_outcomes_count * 100) / request.outcomes_count
                                : 0
                            }%`,
                          }}
                        ></span>
                        <span
                          className={'tw-h-full tw-bg-brand tw-transition-all'}
                          style={{
                            width: `${
                              request.delivered_outcomes_count > 0
                                ? (request.delivered_outcomes_count * 100) / request.outcomes_count
                                : 0
                            }%`,
                          }}
                        ></span>
                        <span
                          className={'tw-h-full tw-bg-green-500 tw-transition-all'}
                          style={{
                            width: `${
                              request.complete_outcomes_count > 0
                                ? (request.complete_outcomes_count * 100) / request.outcomes_count
                                : 0
                            }%`,
                          }}
                        ></span>
                      </div>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent className={'tw-flex tw-max-w-96 tw-flex-col tw-break-words'}>
                    <span>
                      {request.queued_outcomes_count} - {t('Queued')}
                    </span>
                    <span>
                      {request.editing_outcomes_count} - {t('Editing')}
                    </span>
                    <span>
                      {request.delivered_outcomes_count} - {t('Delivered')}
                    </span>
                    <span>
                      {request.complete_outcomes_count} - {t('Completed')}
                    </span>
                  </TooltipContent>
                </Tooltip>
              )}
            </div>

            {(request.assigned_editor || request.editor_note) && (
              <div className={'tw-flex tw-items-center tw-gap-2'}>
                {request.assigned_editor && (
                  <Tooltip>
                    <TooltipTrigger>
                      <Avatar size={'xs'}>
                        <AvatarImage
                          className={'tw-object-cover'}
                          src={
                            request.assigned_editor?.avatar?.generated_conversions.square_200 ??
                            request.assigned_editor?.avatar?.index_url
                          }
                          alt={request.assigned_editor.full_name}
                        />
                        <AvatarFallback className={'tw-bg-orange tw-text-white'}>
                          {str.acronym(request.assigned_editor.full_name)}
                        </AvatarFallback>
                      </Avatar>
                    </TooltipTrigger>
                    <TooltipContent>{request.assigned_editor.full_name}</TooltipContent>
                  </Tooltip>
                )}

                {request.editor_note && (
                  <div className={'tw-line-clamp-2 tw-break-words tw-text-sm'}>
                    {request.editor_note}
                  </div>
                )}
              </div>
            )}

            <div className={'tw-flex tw-flex-wrap tw-gap-2'}>
              {request.priority && <RequestPriorityBadge variant={request.priority} size={'sm'} />}
              <RequestStatusDetails
                wrapper={false}
                size={'sm'}
                needsAttention={request.requested_attention_at}
                needsReedit={request.requested_reedit_at}
                approved={request.approved_at}
              />
              {requestsConditionalUiHelper.shouldDisplayExpectedEditingTime(request) && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <RequestExpectedEditingTimeBadge>
                        {t('{{val}}', {
                          val: formatEditingTime(t, request.expected_editing_time ?? 0),
                        })}
                      </RequestExpectedEditingTimeBadge>
                    </TooltipTrigger>
                    <TooltipContent side={'bottom'} className={'tw-max-w-96 tw-break-words'}>
                      <Trans>
                        Expected <strong>editing</strong> time
                      </Trans>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
              {requestsConditionalUiHelper.shouldDisplayExpectedReeditingTime(request) && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <RequestExpectedReeditingTimeBadge>
                        {t('{{val}}', {
                          val: formatEditingTime(t, request.expected_reediting_time ?? 0),
                        })}
                      </RequestExpectedReeditingTimeBadge>
                    </TooltipTrigger>
                    <TooltipContent side={'bottom'} className={'tw-max-w-96 tw-break-words'}>
                      <Trans>
                        Expected <strong>re-editing</strong> time
                      </Trans>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}

              {!!request.due_date && (
                <Badge variant={'info'}>
                  <Truck size={20} className={'tw-me-1'} />
                  {formatDueDate(t, request.due_date)}
                </Badge>
              )}
            </div>
          </div>
        </TooltipProvider>
      </div>
    </div>
  );
};

export { RequestKanbanCard, type Props as RequestKanbanCardProps };
