import { useQueryParam } from 'use-query-params';

const useUpdateRequestDueDate = () => {
  const [requestId, setRequestId] = useQueryParam<string | undefined>('request-due-date');

  const setRequestDueDateDialogOpen = (requestId: string | undefined) => {
    setRequestId(requestId, 'replaceIn');
  };

  return {
    requestId,
    setRequestDueDateDialogOpen,
  };
};

export { useUpdateRequestDueDate };
