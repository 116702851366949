import React, { forwardRef } from 'react';
import { Badge } from 'src/components/ui/badge';
import { Scissors } from 'lucide-react';

type Props = React.ComponentPropsWithoutRef<typeof Badge>;

export const RequestExpectedReeditingTimeBadge = forwardRef<HTMLDivElement, Props>(
  ({ children, variant = 'dark', ...props }, ref) => {
    return (
      <Badge ref={ref} variant={variant} {...props}>
        <Scissors size={20} className={'tw-me-1'} />
        {children}
      </Badge>
    );
  },
);
