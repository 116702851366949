import React from 'react';
import { CalendarIcon } from 'lucide-react';
import { cn } from 'src/lib/utils';
import { Button } from 'src/components/ui/button';
import { Calendar } from 'src/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover';
import { useTranslation } from 'react-i18next';

export const DatePicker: React.FC<
  React.ComponentPropsWithoutRef<typeof Calendar> & {
    children?: React.ReactNode;
    buttonProps?: React.ComponentPropsWithoutRef<typeof Button>;
    dateFormatString?: string;
    dateFormatParams?: {
      val: Record<string, any>;
    };
  }
> = ({
  children,
  buttonProps,
  dateFormatString = '{{val, datetime}}',
  dateFormatParams,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          {...buttonProps}
          variant={'outline'}
          className={cn(
            'tw-w-[240px] tw-justify-start tw-text-left tw-font-normal',
            !props.selected && 'tw-text-muted-foreground',
            buttonProps?.className,
          )}
        >
          <CalendarIcon size={24} className={'tw-me-2'} />
          {children ||
            (props.selected ? (
              t(dateFormatString, {
                val: props.selected,
                formatParams: dateFormatParams,
              })
            ) : (
              <span>{t('Pick a date')}</span>
            ))}
        </Button>
      </PopoverTrigger>
      <PopoverContent className={'tw-w-auto tw-p-0'} align={'start'}>
        <Calendar {...props} />
      </PopoverContent>
    </Popover>
  );
};
