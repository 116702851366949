import React, { forwardRef } from 'react';
import { Badge } from 'src/components/ui/badge';
import { Timer } from 'lucide-react';

export const RequestExpectedEditingTimeBadge = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof Badge>
>(({ children, variant = 'dark', ...props }, ref) => {
  return (
    <Badge ref={ref} variant={variant} {...props}>
      <Timer size={20} className={'tw-me-1'} />
      {children}
    </Badge>
  );
});
