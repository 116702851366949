import React, { useCallback } from 'react';
import dompurify from 'dompurify';
import { CommentResponse } from 'src/lib/services/api/comment-api';
import { useRequestRevisionVideoPlayerGlobalStore } from 'src/features/revisions/use-request-revision-video-player-global-store';
import { useToast } from 'src/components/ui/use-toast';
import { shallow } from 'zustand/shallow';
import { useRequestRevisionsStore } from 'src/features/revisions/use-revisions-context-provider';
import { useFormatter } from 'src/services/i18n';
import { debounce, isNumber } from 'lodash';
import { formatTime } from 'src/features/video-player/video-player-utils';
import { useTranslation } from 'react-i18next';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import { cn, str } from 'src/lib/utils';
import { Card } from 'src/components/ui/card';
import { If } from 'src/components/If';
import { Button } from 'src/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { Clock2Icon, Copy, Pencil, Reply, SmilePlus, Trash2 } from 'lucide-react';
import EmojiPicker from 'src/components/ui/emoji-picker';
import { Badge } from 'src/components/ui/badge';
import { MinimalMediaCard } from 'src/components/MediaCard/minimal-media-card';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { RevisionChatEditCommentForm } from './revision-chat-edit-comment-form';
import { useRevisionChatCommentReplyStore } from './revision-chat-comment-reply.store';
import { useShallow } from 'zustand/react/shallow';
import { Str } from 'src/helpers';

const { sanitize } = dompurify;

type Props = {
  comment: CommentResponse;
  currentCommentEditing: string;
  setCurrentCommentEditing: React.Dispatch<string>;
  replyDisabled?: boolean;
  reactMutation?: any;
  deleteMutation?: any;
  onSuccessEdit?: () => void;
};

const RevisionChatComment: React.FC<Props> = ({
  comment,
  currentCommentEditing,
  setCurrentCommentEditing,
  replyDisabled,
  reactMutation,
  deleteMutation,
  onSuccessEdit,
}) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { setReplyComment } = useRevisionChatCommentReplyStore(
    useShallow(({ setReplyComment }) => ({
      setReplyComment,
    })),
  );

  const pageStore = useRequestRevisionVideoPlayerGlobalStore(
    ({ pause, seek, timeFormat, setOnMount }) => ({ pause, seek, timeFormat, setOnMount }),
    shallow,
  );

  const request = useDecoratedRequestContext();

  const {
    revisions,
    selectedRevision,
    selectedRevisionMedia,
    setSelectedRevisionMedia,
    setSelectedRevision,
  } = useRequestRevisionsStore();

  const { formatDateTime } = useFormatter();

  const isOfCurrentRevision = selectedRevision?.id === comment.revision;
  const isCurrentlyMounted = selectedRevisionMedia?.id === comment.media_id;

  const referredRevision = revisions.find((r) => r.id === comment.revision);
  const referredMedia = referredRevision?.media.find((m) => m?.id === comment.media_id);
  const referredMediaExists = !!referredMedia;

  const commentHasTimestamp =
    isNumber(comment.revision_timestamp) && !isNaN(comment.revision_timestamp);

  const formattedTime = commentHasTimestamp
    ? formatTime(comment.revision_timestamp!, {
        format: pageStore.timeFormat,
        fps: referredMedia?.fps ?? 24,
      })
    : '';

  const seekToTime = () => {
    if (!commentHasTimestamp || !referredMediaExists) {
      return;
    }

    if (isCurrentlyMounted) {
      pageStore.seek(comment.revision_timestamp!);

      return;
    }

    if (!isOfCurrentRevision) {
      setSelectedRevision(referredRevision?.id);
    }

    setSelectedRevisionMedia(referredMedia.id);
    pageStore.setOnMount(({ seek, pause }) => {
      pause();
      seek(comment.revision_timestamp!);
    });
  };

  const handleDeleteComment = async (id: string) => {
    if (!confirm(t('Are you sure you want to delete this comment?')!)) {
      return;
    }

    deleteMutation.mutate(id);
  };

  const react = useCallback(
    debounce((commentId: string, reaction: string) => {
      reactMutation(commentId, reaction);
    }, 300),
    [],
  );

  return (
    <div className={'tw-relative tw-flex tw-gap-2'} key={comment.id}>
      {!comment.sender?.me && (
        <Avatar size={'sm'} className={'tw-mt-2'}>
          <AvatarImage
            className={'tw-object-cover'}
            src={
              comment.sender?.avatar?.generated_conversions.square_200 ??
              comment.sender?.avatar?.index_url
            }
            alt={comment.sender?.full_name}
          />
          <AvatarFallback delayMs={200} className={'tw-bg-orange tw-text-white'}>
            {str.acronym(comment.sender?.full_name)}
          </AvatarFallback>
        </Avatar>
      )}
      <Card
        className={cn(
          'tw-group tw-relative tw-max-w-[calc(100%-48px)] tw-whitespace-pre-line tw-p-3 tw-transition-all',
          comment.sender?.me
            ? 'tw-ml-auto tw-bg-brand-200 hover:tw-bg-brand-300 focus:tw-bg-brand-300'
            : comment.sender?.type === 'editor'
              ? 'tw-bg-neutral-100 hover:tw-bg-neutral-200 focus:tw-bg-neutral-200'
              : 'tw-bg-orange-200 hover:tw-bg-orange-300 focus:tw-bg-orange-300',
          currentCommentEditing === comment.id && 'tw-w-full',
        )}
        variant={'noShadow'}
      >
        <If
          when={currentCommentEditing !== comment.id}
          else={
            <RevisionChatEditCommentForm
              comment={comment}
              onCancel={() => setCurrentCommentEditing('')}
              onSuccess={() => {
                setCurrentCommentEditing('');
                onSuccessEdit?.();
              }}
            />
          }
        >
          <If when={!request.isCompleted && selectedRevision?.isLatest}>
            <div
              className={
                'tw-absolute tw-right-1 tw-top-1 tw-flex tw-rounded-md tw-border tw-border-brand-400 tw-bg-white tw-opacity-0 group-hover:tw-opacity-100 group-focus:tw-opacity-100'
              }
            >
              <TooltipProvider>
                {!replyDisabled && (
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <Button
                        onClick={() => setReplyComment(comment)}
                        variant={'unset'}
                        size={'iconSm'}
                        className={'hover:tw-text-brand-400'}
                      >
                        <Reply size={16} />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>{t('Reply in thread')}</TooltipContent>
                  </Tooltip>
                )}
                {reactMutation && (
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <EmojiPicker onSelect={(emoji) => react(comment.id, emoji.native)}>
                        <Button
                          variant={'unset'}
                          size={'iconSm'}
                          className={'hover:tw-text-brand-400'}
                        >
                          <SmilePlus size={16} />
                        </Button>
                      </EmojiPicker>
                    </TooltipTrigger>
                    <TooltipContent>{t('React')}</TooltipContent>
                  </Tooltip>
                )}
                <If when={comment.sender?.me}>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <Button
                        onClick={() => setCurrentCommentEditing(comment.id)}
                        variant={'unset'}
                        size={'iconSm'}
                        className={'hover:tw-text-brand-400'}
                      >
                        <Pencil size={16} />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>{t('Edit')}</TooltipContent>
                  </Tooltip>
                </If>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <Button
                      variant={'unset'}
                      size={'iconSm'}
                      className={'hover:tw-text-brand-400'}
                      onClick={() => {
                        toast({ description: 'Text copied to clipboard!' });
                        navigator.clipboard.writeText(comment.comment);
                      }}
                    >
                      <Copy size={16} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{t('Copy')}</TooltipContent>
                </Tooltip>
                {deleteMutation && (
                  <If when={comment.sender?.me}>
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger asChild>
                        <Button
                          disabled={deleteMutation.isLoading}
                          variant={'unset'}
                          size={'iconSm'}
                          className={'hover:tw-text-brand-400'}
                          onClick={() => {
                            handleDeleteComment(comment.id);
                          }}
                        >
                          <Trash2 size={16} />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>{t('Delete')}</TooltipContent>
                    </Tooltip>
                  </If>
                )}
              </TooltipProvider>
            </div>
          </If>

          <div className={'tw-mb-2 tw-flex tw-flex-row tw-flex-wrap'}>
            <div className={'tw-mr-2 tw-text-sm tw-font-semibold tw-text-text-strong'}>
              {comment.sender?.full_name}
            </div>
            <div className={'tw-text-sm tw-text-text-subtle'}>
              {formatDateTime(comment.created_at)}
            </div>
          </div>

          <If when={commentHasTimestamp}>
            <Button
              className={
                'tw-group tw-mb-2 tw-flex tw-items-center tw-justify-start tw-gap-2 tw-pl-0'
              }
              disabled={!referredMediaExists}
              variant={'unset'}
              size={'unset'}
              onClick={seekToTime}
            >
              <Badge
                variant={'brand'}
                className={cn('pointer gap-2 fs-6 group-hover:tw-bg-brand-500')}
              >
                <Clock2Icon size={16} />
                {formattedTime}
              </Badge>
              <If
                when={!referredMediaExists}
                else={
                  <div
                    className={
                      'tw-line-clamp-1 tw-max-w-[70%] tw-overflow-hidden tw-break-all tw-text-sm group-hover:tw-text-brand-500'
                    }
                  >
                    {referredMedia?.file_name}
                  </div>
                }
              >
                <div className={'tw-pr-2 tw-text-sm tw-italic tw-text-text-subtle'}>
                  {t('File not found')}
                </div>
              </If>
            </Button>
          </If>

          <If when={!!comment.comment}>
            <div
              className={'tw-break-words'}
              dangerouslySetInnerHTML={{
                __html: Str.wrapHrefs(
                  sanitize(comment.comment ?? '', {
                    USE_PROFILES: { html: true },
                  }),
                ),
              }}
            />
          </If>

          <If when={!!comment.edited_at}>
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger asChild>
                  <div className={'tw-mt-0.5 tw-text-sm tw-text-text-subtle'}>({t('Edited')})</div>
                </TooltipTrigger>
                <TooltipContent>{formatDateTime(comment.edited_at!)}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </If>
          <If when={!!comment.media.length}>
            <div className={'tw-mt-1.5 tw-flex tw-flex-wrap tw-gap-2'}>
              {comment.media.map((media) => (
                <MinimalMediaCard key={media.id} media={media} showTitle canView />
              ))}
            </div>
          </If>
          {!!comment.reactions && (
            <div className={'tw-mt-2 tw-flex tw-flex-wrap tw-gap-0.5'}>
              {Object.keys(comment.reactions).map((emoji: string) => {
                return (
                  <Button
                    onClick={() => react(comment.id, emoji)}
                    key={emoji}
                    disabled={!reactMutation}
                    variant={'unset'}
                    size={'unset'}
                    className={
                      'tw-flex tw-gap-0.5 tw-bg-brand-300/80 tw-px-1 tw-py-0.5 hover:tw-bg-brand-400/80'
                    }
                  >
                    <span>{emoji}</span>
                    <span className={'tw-text-sm'}>{comment.reactions![emoji].length}</span>
                  </Button>
                );
              })}
            </div>
          )}
          {!!comment.replies_count && !!comment.last_reply_at && !replyDisabled && (
            <Button
              onClick={() => setReplyComment(comment)}
              variant={'unset'}
              className={'tw-group/threads-preview tw-mt-3 tw-flex tw-p-0'}
            >
              <div className={'tw-flex tw-gap-2 tw-text-sm'}>
                <span className={'tw-text-brand'}>
                  {comment.replies_count} {comment.replies_count > 1 ? t('replies') : t('reply')}
                </span>
                <span
                  className={
                    'tw-font-normal tw-text-text-subtle group-hover/threads-preview:tw-hidden'
                  }
                >
                  {formatDateTime(comment.last_reply_at)}
                </span>
                <span
                  className={
                    'tw-hidden tw-font-normal tw-text-text-subtle group-hover/threads-preview:tw-flex'
                  }
                >
                  {t('View thread')}
                </span>
              </div>
            </Button>
          )}
        </If>
      </Card>
    </div>
  );
};

export { RevisionChatComment };
