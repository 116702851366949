import {
  RequestFlow,
  requestFlow,
  requestStatus,
  RequestStatus,
} from 'src/lib/services/api/request-api';
import { generatePath } from 'react-router-dom';
import { appRoutes } from 'src/routes';
import { TFunction } from 'i18next';

const getRequestNavigationRoute = (
  companyId: string,
  requestId: string,
  status: RequestStatus,
  flow: RequestFlow,
) => {
  if (
    flow === requestFlow.multiOutcome ||
    flow === requestFlow.repurpose ||
    status === requestStatus.queued
  ) {
    return generatePath(appRoutes.request, { companyId, requestId });
  }

  return generatePath(appRoutes.requestRevisions, { companyId, requestId });
};

const requestStatusTranslationsMap = (status: RequestStatus | 'archived' | 'all') => {
  switch (status) {
    case 'draft':
      return 'Draft';
    case 'queued':
      return 'Queued';
    case 'currently_editing':
      return 'Editing';
    case 'delivered':
      return 'Ready for review';
    case 'complete':
      return 'Complete';
    case 'archived':
      return 'Archived';
    case 'all':
      return 'All';
    default:
      return 'Unknown';
  }
};

export const formatDueDate: (t: TFunction, dueDate: string | Date) => string = (t, dueDate) => {
  return t('Estimated due date: {{val, datetime}}', {
    val: new Date(dueDate),
    formatParams: {
      val: { year: 'numeric', month: 'short', day: 'numeric' },
    },
  });
};

export const formatEditingTime: (t: TFunction, editingTime: number) => string = (
  t,
  editingTimeMinutes,
) => {
  if (editingTimeMinutes >= 60) {
    const hours = Math.floor(editingTimeMinutes / 60);
    const minutes = editingTimeMinutes % 60;
    return t('{{hours}} h {{minutes}} min', { hours, minutes });
  }

  return t('{{val}} min', { val: editingTimeMinutes });
};

export { getRequestNavigationRoute, requestStatusTranslationsMap };
