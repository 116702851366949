import { requestsResponseHelper } from 'src/features/requests/requests-response.helper';

export const requestsConditionalUiHelper: {
  shouldDisplayExpectedEditingTime: (request: {
    flow: string | any;
    status: string | any;
    revisions_count?: number;
  }) => boolean;
  shouldDisplayExpectedReeditingTime: (request: {
    flow: string | any;
    status: string | any;
    revisions_count?: number;
    requested_reedit_at?: string | null;
    approved_at?: string | null;
  }) => boolean;
} = {
  shouldDisplayExpectedEditingTime: (request) =>
    !requestsResponseHelper.isFolder(request) &&
    requestsResponseHelper.isQueued(request) &&
    requestsResponseHelper.isEditing(request) &&
    // If request has any revision then it means that it was in editing state before
    !requestsResponseHelper.hasAnyRevision(request),
  shouldDisplayExpectedReeditingTime: (request) =>
    !requestsResponseHelper.isFolder(request) &&
    (requestsResponseHelper.isQueued(request) || requestsResponseHelper.isDelivered(request)) &&
    requestsResponseHelper.hasVideoRevision(request) &&
    (requestsResponseHelper.isApproved(request) || requestsResponseHelper.needsReedit(request)),
};
