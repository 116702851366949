import { AxiosRequestConfig } from 'axios';
import { Api } from 'src/api';
import { httpConfig } from 'src/lib/modules/http';
import { ApiQueryObject } from 'src/lib/services/api-query-params';
import { PaginatedResponse } from 'src/api/Client';
import {
  CommentResponse,
  StoreCommentRequest,
} from 'src/lib/services/api/comment-api/comment-client.types';

const baseUrl = `${httpConfig.options.baseURL}/comments`;
// dunno or split clients or not
const revisionBaseUrl = `${httpConfig.options.baseURL}/revision-comments`;

const filterRequestComments = (
  model: string,
  params?: ApiQueryObject & { from_revision?: string },
  config?: AxiosRequestConfig,
): Promise<PaginatedResponse<CommentResponse>> =>
  Api.http.get(`${baseUrl}/${model}/filter`, { params, ...config });

const filterCommentReplies = (
  model: string,
  params?: ApiQueryObject,
  config?: AxiosRequestConfig,
): Promise<PaginatedResponse<CommentResponse>> =>
  Api.http.get(`${revisionBaseUrl}/${model}/replies`, { params, ...config });

const store = (model: string, data: StoreCommentRequest): Promise<CommentResponse> =>
  Api.http.post(`${baseUrl}/${model}`, data);

const remove = (model: string): Promise<CommentResponse> => Api.http.delete(`${baseUrl}/${model}`);

const update = (
  model: string,
  data: Pick<StoreCommentRequest, 'comment'>,
): Promise<CommentResponse> => Api.http.put(`${revisionBaseUrl}/${model}`, data);

const react = (model: string, data: { reaction: string }): Promise<CommentResponse> =>
  Api.http.put(`${revisionBaseUrl}/${model}/react`, data);

const reply = (model: string, data: StoreCommentRequest): Promise<CommentResponse> =>
  Api.http.post(`${revisionBaseUrl}/${model}/reply`, data);

export { filterRequestComments, filterCommentReplies, store, remove, update, react, reply };
