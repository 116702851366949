import React, { useMemo } from 'react';
import { Button } from 'src/components/ui/button';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { requestClient } from 'src/lib/services/api/request-api';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, type InferType, date } from 'yup';
import { extractHookFormErrors } from 'src/components/Form/Form.utils';
import { FieldComplex } from 'src/components/Form/hook-form/field-complex';
import { useTranslation } from 'react-i18next';
import { ButtonLoader } from 'src/components/ui/loader';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { useQueryClient } from '@tanstack/react-query';
import { authQueries } from 'src/api/queries';
import { Skeleton } from 'src/components/ui/skeleton';
import { useToast } from 'src/components/ui/use-toast';
import { DatePickerInput } from 'src/components/ui/hook-form/date-picker-input';
import { getRequestOutcomesQueryKey } from 'src/features/requests/request-outcome/use-request-outcomes-query-context';

type Props = {
  onSubmit?: () => void;
};

const UpdateRequestDueDateDialogForm: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const request = useDecoratedRequestContext();
  const client = useQueryClient();

  const validationSchema = useMemo(
    () =>
      object({
        due_date: date().nullable(),
      }),
    [],
  );

  type FormValues = InferType<typeof validationSchema>;

  const form = useForm<FormValues>({
    defaultValues: {
      due_date: request.due_date ? new Date(request.due_date) : null,
    },
    resolver: yupResolver(validationSchema),
  });

  const submitHandler: SubmitHandler<FormValues> = async (values) => {
    try {
      await requestClient.changeDueDate(request.id, { due_date: values.due_date });

      toast({ description: t('Request estimated due date updated') });

      client.invalidateQueries({
        queryKey: [authQueries.request, request.id],
      });
      client.invalidateQueries({
        queryKey: [authQueries.requests, request.status],
      });
      client.invalidateQueries({
        queryKey: [authQueries.requests, 'all'],
      });
      // todo: need better queryKey for company requests
      client.invalidateQueries({
        queryKey: [authQueries.company, request.company?.id],
      });
      // todo: need better queryKey for user requests
      client.invalidateQueries({
        queryKey: [authQueries.user, request.editor?.id],
      });

      if (request.isOutcomeFlow) {
        client.invalidateQueries({
          queryKey: getRequestOutcomesQueryKey(request.parent_id!, {}),
        });
      }

      onSubmit?.();
    } catch (e) {
      const errors = extractHookFormErrors(e) ?? {};

      Object.keys(errors).forEach((key) => {
        form.setError(key as any, { type: 'custom', message: errors[key] });
      });
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)}>
        <div className={'tw-flex tw-flex-col tw-gap-4 tw-py-4'}>
          <FieldComplex name={'due_date'} containerClass={'tw-flex tw-flex-col tw-gap-2'}>
            <DatePickerInput
              buttonProps={{
                className: 'tw-w-full',
              }}
              name={'due_date'}
              mode={'single'}
              disabled={{ before: new Date() }}
              dateFormatString={'Up to {{val, datetime}}'}
              dateFormatParams={{
                val: {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                },
              }}
            />
          </FieldComplex>
        </div>
        <div className={'tw-flex'}>
          <Button
            type={'submit'}
            variant={'brand'}
            size={'responsive'}
            className={'tw-w-full md:tw-ms-auto md:tw-w-auto'}
            disabled={form.formState.isSubmitting}
          >
            {t('Submit')}
            <ButtonLoader loading={form.formState.isSubmitting} />
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

const UpdateRequestDueDateDialogFormSkeleton: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={'tw-flex tw-flex-col tw-gap-4 tw-py-4'}>
        <div className={'tw-flex tw-grow tw-flex-col'}>
          <Skeleton className={'tw-mb-3 tw-h-6 tw-w-24'} />
          <Skeleton className={'tw-h-12 tw-w-full'} />
        </div>
      </div>
      <div className={'tw-flex'}>
        <Button
          type={'button'}
          disabled
          variant={'brand'}
          size={'responsive'}
          className={'tw-w-full md:tw-ms-auto md:tw-w-auto'}
        >
          {t('Submit')}
        </Button>
      </div>
    </div>
  );
};

export { UpdateRequestDueDateDialogForm, UpdateRequestDueDateDialogFormSkeleton };
