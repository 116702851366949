import React, { useState } from 'react';
import { TabContent } from 'reactstrap';
import {
  Card,
  NavItem,
  NavLink,
  OrderInfoTabPane,
  OrderInfoTabInfoPaneContent,
  OrderInfoTabPaneGroupItem,
  OrderInfoNavLink,
} from '@becreatives/becreatives-ui';
import { OrderInfoNav, OrderInfoStatusCardGrid } from './RevisionsContentBottom.styled';
import { generatePath, Link } from 'react-router-dom';
import { appRoutes } from 'src/routes/routes.types';
import { OrderInfoMediaNavLink } from './OrderInfoMediaNavLink';
import { If } from 'src/components/If';
import { useTranslation } from 'react-i18next';
import { OrderNotes } from './OrderNotes';
import Str from 'src/helpers/str';
import dompurify from 'dompurify';
import { RequestStatusBadge } from 'src/features/requests/request-status-badge';
import { totalLengthToString } from 'src/features/requests/request-fields/request-field.helper';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { useCompanyId } from 'src/features/companies/use-company-id';
import { CardContent } from 'src/components/ui/card';
import RequestActivityList from 'src/features/requests/request-activity/request-activity-list';
import { ScrollArea } from 'src/components/ui/scroll-area';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { Button } from 'src/components/ui/button';
import { Pencil } from 'lucide-react';
import { useUpdateRequestDueDate } from 'src/features/requests/update-request-due-date-dialog/use-update-request-due-date';
import { UpdateRequestDueDateDialog } from 'src/features/requests/update-request-due-date-dialog/update-request-due-date-dialog';
import { RenderForAdmin } from 'src/components/Middleware';
import { roles } from 'src/api/services/RoleClient';
import { formatDueDate } from 'src/features/requests/request.helper';

const RevisionsContentBottom: React.FC = () => {
  const { t } = useTranslation('attributes/request');

  const companyId = useCompanyId();
  const [activeTab, setActiveTab] = useState('general');
  const { status = 'queued', editor_note, ...request } = useDecoratedRequestContext();
  const { setRequestDueDateDialogOpen } = useUpdateRequestDueDate();

  const contentFallback = t('N/A');

  const tabs = [
    {
      title: t('General'),
      id: 'general',
    },
    {
      title: t('Resolutions'),
      id: 'resolutions',
    },
    {
      title: t('Description'),
      id: 'description',
    },
    {
      title: t('Supporting materials'),
      id: 'supporting_materials',
    },
    {
      title: t('Extra'),
      id: 'extra',
    },
  ];

  const tabContent: Record<string, Record<'title' | 'content', any>[]> = {
    general: [
      {
        title: t('Type'),
        content: request?.type
          ? t(`type.${request.type}`, { ns: 'models/request' })
          : contentFallback,
      },
      {
        title: t('Title'),
        content: request?.title || contentFallback,
      },
      {
        title: t('Brand'),
        content: !request?.brand ? (
          contentFallback
        ) : (
          <OrderInfoNavLink
            as={Link as any}
            to={generatePath(appRoutes.brand, { companyId, brandId: request.brand.id })}
            className={'text-decoration-underline'}
          >
            {request.brand.name}
          </OrderInfoNavLink>
        ),
      },
    ],
    resolutions: [
      {
        title: t('Main size'),
        content: request?.resolutions?.main_size || contentFallback,
      },
      {
        title: t('Resizes'),
        content: request?.resolutions?.resize?.join(', ') || contentFallback,
      },
    ],
    description: [
      {
        title: t('Total length'),
        content:
          totalLengthToString(request?.total_length) ||
          request?.description?.total_length ||
          contentFallback,
      },
      {
        title: t('Video request description'),
        content: (
          <p
            className={'m-0'}
            dangerouslySetInnerHTML={{
              __html: Str.wrapHrefs(
                dompurify.sanitize(
                  request?.description?.video_request_description || contentFallback,
                  {
                    USE_PROFILES: { html: true },
                  },
                ),
              ),
            }}
          />
        ),
      },
    ],
    supporting_materials: [
      {
        title: t('Supporting materials'),
        content: !request?.media?.length ? (
          contentFallback
        ) : (
          <>
            {request.media.map((thumbnail, i) => (
              <OrderInfoMediaNavLink key={i} media={thumbnail}>
                <If when={request!.media!.length - 1 > i}>{','}&nbsp;</If>
              </OrderInfoMediaNavLink>
            ))}
          </>
        ),
      },
      {
        title: t('Music preference'),
        content: request?.supporting_materials?.music_preference || contentFallback,
      },
    ],
    extra: [
      {
        title: t('Captions'),
        content: request?.extra?.subtitles || contentFallback,
      },
      {
        title: t('End card'),
        content: request?.extra?.end_card ? t('yes', { ns: 'common' }) : t('no', { ns: 'common' }),
      },
      {
        title: t('Examples'),
        content: request?.extra?.examples || contentFallback,
      },
    ],
  };

  return (
    <div className={'tw-flex tw-flex-col tw-gap-6'}>
      <h3 className="tw-text-2xl tw-font-semibold">{t('Request info')}</h3>

      <Card>
        <OrderInfoStatusCardGrid>
          <b>{t('Status')}:</b>
          <div>
            <RequestStatusBadge variant={status} />
          </div>

          <b>{t('Editor note')}:</b>
          <p>{editor_note || t('-')}</p>

          <b>{t('Estimated due date')}</b>
          <div className={'tw-flex tw-items-center'}>
            <p>{request.due_date ? formatDueDate(t, request.due_date) : t('-')}</p>
            {request.isCurrentlyEditing && !request.isFolder && (
              <RenderForAdmin and={[roles.teamLead]}>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        type={'button'}
                        variant={'ghost'}
                        size={'iconSm'}
                        className={'tw-ms-2 tw-text-neutral-600 tw-transition-colors'}
                        onClick={() => {
                          setRequestDueDateDialogOpen(request.id);
                        }}
                      >
                        <Pencil className={'tw-size-4'} />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>{t('Edit field')}</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </RenderForAdmin>
            )}
          </div>
        </OrderInfoStatusCardGrid>
      </Card>

      <Card>
        <OrderInfoNav>
          {tabs.map((tab, i) => (
            <NavItem key={i}>
              <NavLink
                active={tab.id === activeTab}
                onClick={() => setActiveTab(tab.id)}
                color={'primary'}
                className={'py-2 py-md-3'}
              >
                {tab.title}
              </NavLink>
            </NavItem>
          ))}
        </OrderInfoNav>
        <TabContent activeTab={activeTab}>
          {tabs.map((tab, i) => (
            <OrderInfoTabPane key={i} tabId={tab.id}>
              <OrderInfoTabInfoPaneContent>
                {tabContent[tab.id].map((content, j) => (
                  <OrderInfoTabPaneGroupItem key={j}>
                    <b>{content.title}</b>
                    <span>{content.content}</span>
                  </OrderInfoTabPaneGroupItem>
                ))}
              </OrderInfoTabInfoPaneContent>
            </OrderInfoTabPane>
          ))}
        </TabContent>
      </Card>

      <h3 className="tw-text-2xl tw-font-semibold">
        {t('revisions.order.notes.section_title', { ns: 'pages/shared' })}
      </h3>

      <Card>
        <OrderNotes />
      </Card>

      <div className={'tw-flex tw-flex-col tw-gap-4'}>
        <h3 className="tw-text-2xl tw-font-semibold">{t('Activity')}</h3>

        <Card>
          <CardContent className={'tw-p-4 md:tw-p-6'}>
            <ScrollArea>
              <div className="tw-max-h-96">
                <RequestActivityList requestId={request.id} />
              </div>
            </ScrollArea>
          </CardContent>
        </Card>
      </div>

      <UpdateRequestDueDateDialog />
    </div>
  );
};

export { RevisionsContentBottom };
