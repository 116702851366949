import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from 'src/lib/utils';

export const badgeVariants = cva(
  'tw-inline-flex tw-items-center tw-rounded-full tw-border tw-px-2.5 tw-py-0.5 tw-text-sm tw-font-semibold tw-transition-colors focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2',
  {
    variants: {
      variant: {
        default:
          'tw-border-transparent tw-bg-primary tw-text-primary-foreground hover:tw-bg-primary/80',
        brand: 'tw-bg-brand-400 tw-text-white hover:tw-bg-brand-500',
        brandLight: 'tw-border-transparent tw-bg-brand-300 tw-text-brand-500',
        destructive:
          'tw-border-transparent tw-bg-destructive tw-text-white hover:tw-bg-destructive/80',
        yellow: 'tw-border-transparent tw-bg-yellow-500 tw-text-dark',
        info: 'tw-bg-blue-300 tw-text-blue-500',
        outline: 'tw-text-foreground',
        warning: 'tw-border-yellow tw-bg-yellow-100 tw-text-yellow-900 [&>svg]:tw-text-yellow-900',
        brandExtraLight: 'tw-border-transparent tw-bg-brand-200 tw-text-text-base',
        unset: '',
        dark: 'tw-b-accent tw-bg-stone-700 tw-text-accent [&>svg]:tw-text-accent',
      },
      padding: {
        unset: '',
        sm: 'tw-px-2.5 tw-py-0.5',
      },
      rounded: {
        unset: '',
        full: 'tw-rounded-full',
        sm: 'tw-rounded-sm',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface BadgeProps
  extends React.ComponentPropsWithoutRef<'div'>,
    VariantProps<typeof badgeVariants> {}

export const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ className, variant, ...props }, ref) => (
    <div ref={ref} className={cn(badgeVariants({ variant }), className)} {...props} />
  ),
);
Badge.displayName = 'Badge';
