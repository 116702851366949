import { ConfirmState, IConfirmAction } from './confirm-dialog.types';

const SHOW_CONFIRM = 'SHOW_CONFIRM';
const HIDE_CONFIRM = 'HIDE_CONFIRM';

const initialState: () => ConfirmState = () => ({
  show: false,
  text: '',
});

const confirmDialogReducer = (state = initialState(), action: IConfirmAction): ConfirmState => {
  switch (action.type) {
    case SHOW_CONFIRM:
      return {
        show: true,
        text: action.payload.text,
        options: action.payload.options,
      };
    case HIDE_CONFIRM:
      return action.payload;
    default:
      return state;
  }
};

export { SHOW_CONFIRM, HIDE_CONFIRM, initialState, confirmDialogReducer };
