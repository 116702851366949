import {
  requestFlow,
  requestMultipleOutcomesType,
  RequestResponse,
  requestStatus,
  RequestStatus,
} from 'src/lib/services/api/request-api';
import { totalLengthToString } from 'src/features/requests/request-fields/request-field.helper';

const decorateRequest = (request?: RequestResponse) => {
  if (!request) {
    return request;
  }

  const isOfStatus = (status: RequestStatus | RequestStatus[]): boolean => {
    if (Array.isArray(status)) {
      return status.includes(request?.status as any);
    }

    return status === request?.status;
  };

  const hasRevisions = !!request.revisions_count || request.revisions.length > 0,
    revisionsCount = (request.revisions_count || request.revisions.length) ?? 0,
    isQueued = isOfStatus(requestStatus.queued),
    isCurrentlyEditing = isOfStatus(requestStatus.currentlyEditing),
    isDelivered = isOfStatus(requestStatus.delivered),
    needsReedits = !!request?.requested_reedit_at,
    needsAttention = !!request?.requested_attention_at,
    isApproved = !!request?.approved_at,
    isCompleted = isOfStatus(requestStatus.complete),
    canRequestAttention = isQueued,
    media = request.media?.footage ?? [],
    hasMedia = !!media.length,
    hasOutcomes = false,
    isDefaultFlow = request.flow === requestFlow.default,
    isOutcomeFlow = request.flow === requestFlow.outcome,
    isMultiOutcomeFlow = request.flow === requestFlow.multiOutcome,
    isRepurposeFlow = request.flow === requestFlow.repurpose,
    isFolder = isMultiOutcomeFlow || isRepurposeFlow,
    isEditorsChoice = request.outcome_type === requestMultipleOutcomesType.editorChoice,
    isCustom = request.outcome_type === requestMultipleOutcomesType.custom,
    hasEditor = !!request.editor,
    editor = request.editor,
    canAddOutcome = (isMultiOutcomeFlow || isRepurposeFlow) && !isCompleted,
    totalLength = totalLengthToString(request.total_length) || request.description?.total_length;

  return {
    ...request,
    raw: request,
    isQueued,
    isCurrentlyEditing,
    isDelivered,
    needsReedits,
    needsAttention,
    isApproved,
    isCompleted,
    canRequestAttention,
    hasRevisions,
    revisionsCount,
    media,
    hasMedia,
    hasOutcomes,
    isDefaultFlow,
    isMultiOutcomeFlow,
    isRepurposeFlow,
    isFolder,
    isEditorsChoice,
    isCustom,
    isOutcomeFlow,
    canAddOutcome,
    hasEditor,
    editor,
    totalLength,
    isOfStatus,
  };
};

export type DecoratedRequest = ReturnType<typeof decorateRequest>;
export { decorateRequest };
